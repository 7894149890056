<template>
  <v-progress-linear
    :active="spinnerVisible"
    :indeterminate="true"
    absolute
    bottom
    color="yellow darken-2"
  ></v-progress-linear>
</template>

<script>
import { eventHub } from "@/main";

export default {
  name: "ProgressBar",

  data() {
    return {
      spinnerVisible: false,
    };
  },

  beforeMount() {
    eventHub.$on("LOADING", (loading) => (this.spinnerVisible = loading));
  },

  beforeDestroy() {
    eventHub.$off("LOADING", (loading) => {
      this.spinnerVisible = loading;
    });
  },
};
</script>