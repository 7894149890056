<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-form
            ref="form"
            v-model="validForm"
            @submit.prevent="resetPassword"
          >
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="security-form-title"
                >Definir nova senha
                <div class="logo-container">
                  <img src="../../assets/images/logo.png" />
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <div style="position: relative">
              <ProgressBar></ProgressBar>
            </div>
            <v-card-text>
              <v-text-field
                label="E-mail"
                name="email"
                required
                :rules="[$rules.required]"
                v-model="user.email"
                prepend-icon="mdi-email"
                type="email"
              ></v-text-field>

              <v-text-field
                id="password"
                label="Nova senha"
                name="password"
                required
                v-model="user.password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[$rules.required]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                id="passwordConfirmation"
                label="Confirmação da senha"
                name="passwordConfirmation"
                required
                v-model="user.passwordConfirmation"
                prepend-icon="mdi-lock"
                :append-icon="
                  showConfirmationPassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                :rules="[
                  $rules.required,
                  $rules.checkSamePassword(
                    user.passwordConfirmation,
                    user.password
                  ),
                ]"
                :type="showConfirmationPassword ? 'text' : 'password'"
                @click:append="
                  showConfirmationPassword = !showConfirmationPassword
                "
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="primary"
                :loading="resetPasswordButton.loading"
                >Solicitar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userApi from "@/common/api/user.api";
import toaster from "@/common/util/toaster";
import ProgressBar from "@/components/layout/ProgressBar";

export default {
  name: "ResetPassword",

  components: {
    ProgressBar,
  },

  data() {
    return {
      user: {},
      validForm: true,
      showPassword: false,
      showConfirmationPassword: false,
      resetPasswordButton: {
        loading: false,
      },
    };
  },

  created() {
    this.user.email = decodeURIComponent(this.$route.query.email);
  },

  methods: {
    resetPassword() {
      this.$refs.form.validate();

      if (this.validForm) {
        let data = {
          email: this.user.email,
          resetToken: this.$route.params.token,
          password: this.user.password,
        };

        this.resetPasswordButton.loading = true;

        userApi
          .resetPassword(data)
          .then(() => {
            this.resetPasswordButton.loading = false;
            toaster.show("Senha redefinida com sucesso!");
            this.$router.replace("/login");
          })
          .catch(() => (this.resetPasswordButton.loading = false));
      }
    },
  },
};
</script>